export const options = [
  { value: 'directCare', label: 'Direct Care' },
  { value: 'publicHealth', label: 'Public Health' },
  { value: 'administration', label: 'Administration' },
  { value: 'patient', label: 'Patient' },
  { value: 'research', label: 'Research' },
]

export const languages = [
  { value: 'All', label: 'All' },
  { value: 'ab', label: 'Abkhaz' },
  { value: 'aa', label: 'Afar' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'ak', label: 'Akan' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'an', label: 'Aragonese' },
  { value: 'hy', label: 'Armenian' },
  { value: 'as', label: 'Assamese' },
  { value: 'av', label: 'Avaric' },
  { value: 'ae', label: 'Avestan' },
  { value: 'ay', label: 'Aymara' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'bm', label: 'Bambara' },
  { value: 'ba', label: 'Bashkir' },
  { value: 'eu', label: 'Basque' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bh', label: 'Bihari' },
  { value: 'bi', label: 'Bislama' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'br', label: 'Breton' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'my', label: 'Burmese' },
  { value: 'ca', label: 'Catalan' },
  { value: 'ch', label: 'Chamorro' },
  { value: 'ce', label: 'Chechen' },
  { value: 'zh', label: 'Chinese' },
  { value: 'cv', label: 'Chuvash' },
  { value: 'kw', label: 'Cornish' },
  { value: 'co', label: 'Corsican' },
  { value: 'cr', label: 'Cree' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'nl', label: 'Dutch' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'et', label: 'Estonian' },
  { value: 'ee', label: 'Ewe' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fj', label: 'Fijian' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'gl', label: 'Galician' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'gn', label: 'Guaraní' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'ht', label: 'Haitian' },
  { value: 'ha', label: 'Hausa' },
  { value: 'hz', label: 'Herero' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ho', label: 'Hiri Motu' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'ia', label: 'Interlingua' },
  { value: 'id', label: 'Indonesian' },
  { value: 'ga', label: 'Irish' },
  { value: 'ig', label: 'Igbo' },
  { value: 'ik', label: 'Inupiaq' },
  { value: 'io', label: 'Ido' },
  { value: 'is', label: 'Icelandic' },
  { value: 'it', label: 'Italian' },
  { value: 'iu', label: 'Inuktitut' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'kl', label: 'Kalaallisut' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kr', label: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'km', label: 'Khmer' },
  { value: 'rw', label: 'Kinyarwanda' },
  { value: 'ky', label: 'Kirghiz' },
  { value: 'kv', label: 'Komi' },
  { value: 'kg', label: 'Kongo' },
  { value: 'ko', label: 'Korean' },
  { value: 'la', label: 'Latin' },
  { value: 'ln', label: 'Lingala' },
  { value: 'lo', label: 'Lao' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lv', label: 'Latvian' },
  { value: 'gv', label: 'Manx' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt', label: 'Maltese' },
  { value: 'mi', label: 'Māori' },
  { value: 'mh', label: 'Marshallese' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'na', label: 'Nauru' },
  { value: 'ne', label: 'Nepali' },
  { value: 'ng', label: 'Ndonga' },
  { value: 'nn', label: 'Norwegian' },
  { value: 'ii', label: 'Nuosu' },
  { value: 'oc', label: 'Occitan' },
  { value: 'om', label: 'Oromo' },
  { value: 'or', label: 'Oriya' },
  { value: 'pi', label: 'Pāli' },
  { value: 'fa', label: 'Persian' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'qu', label: 'Quechua' },
  { value: 'rm', label: 'Romansh' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sa', label: 'Sanskrit' },
  { value: 'sc', label: 'Sardinian' },
  { value: 'sd', label: 'Sindhi' },
  { value: 'sm', label: 'Samoan' },
  { value: 'sg', label: 'Sango' },
  { value: 'sr', label: 'Serbian' },
  { value: 'sn', label: 'Shona' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovene' },
  { value: 'so', label: 'Somali' },
  { value: 'es', label: 'Spanish' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sw', label: 'Swahili' },
  { value: 'ss', label: 'Swati' },
  { value: 'sv', label: 'Swedish' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te', label: 'Telugu' },
  { value: 'tg', label: 'Tajik' },
  { value: 'th', label: 'Thai' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'tn', label: 'Tswana' },
  { value: 'to', label: 'Tonga' },
  { value: 'tr', label: 'Turkish' },
  { value: 'ts', label: 'Tsonga' },
  { value: 'tt', label: 'Tatar' },
  { value: 'tw', label: 'Twi' },
  { value: 'ty', label: 'Tahitian' },
  { value: 'ug', label: 'Uighur' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 've', label: 'Venda' },
  { value: 'vo', label: 'Volapük' },
  { value: 'wa', label: 'Walloon' },
  { value: 'cy', label: 'Welsh' },
  { value: 'wo', label: 'Wolof' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'yi', label: 'Yiddish' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'za', label: 'Zhuang' },
]

export const filters = [
  {
    label: 'Publication type',
    group: 'publication_type',
    items: [
      {
        value: 'consensus',
        label: 'Consensus Opinion',
        helpText:
          'Represents broad acceptance of an established point-of-view, such as often appear in professional journals or outputs from professional societies.',
      },
      {
        value: 'paper',
        label: 'Paper/Article',
        helpText:
          'Availability of the asset as a formal published work, such as “white papers”, inclusion in journals, etc.',
      },
      {
        value: 'review',
        label: 'Systematic Review',
        helpText:
          'Answer to pre-defined research questions using explicit, reproducible methods to identify, critically appraise, and combine results of primary research study [NCBI/NLM]',
      },
      {
        value: 'editorial',
        label: 'Editorial',
        helpText:
          'Point-of-view from an organization, society, or publication on behalf of that entity',
      },
      {
        value: 'letter',
        label: 'Letter to the Editor',
        helpText:
          'Opinion from a reader, generally a subject matter expert on the topic.',
      },
      {
        value: 'news',
        label: 'News',
        helpText:
          'Information provided as an item of interest from the news media.',
      },
      {
        value: 'web',
        label: 'Web',
        helpText:
          'Web based presentation-would cite source (social media, blog, vlog etc.)',
      },
      {
        value: 'other',
        label: 'Other',
        helpText: 'Enter for curation as additional entry based on volume.',
      },
    ],
  },
  {
    label: 'Activity Type (soon)',
    group: 'activity_type',
    items: [],
  },
  {
    label: 'Source',
    group: 'source_type',
    items: [
      {
        value: 'Government-Sourced',
        label: 'Government-Sourced',
        helpText:
          'Represents a contribution from a government entity at any level',
      },
      {
        value: 'NGO-Sourced',
        label: 'NGO-Sourced',
        helpText:
          'Represents non-governmental entities, such as public-private partnerships or quasi-government commercial entities.',
      },
      {
        value: 'Commercially Sourced',
        label: 'Commercially Sourced',
        helpText: 'Represents private-sector entities, companies, etc',
      },
      {
        value: 'College/Professional Organization',
        label: 'College/Professional Organization',
        helpText:
          'Represents specialty organizations, such as clinical colleges, professional consortia, standards groups, etc.',
      },
      {
        value: 'Healthcare Delivery System',
        label: 'Healthcare Delivery System',
        helpText:
          'Represents healthcare provider organizations, care delivery networks, etc',
      },
      {
        value: 'Other',
        label: 'Other',
        helpText:
          'Any institution not adequately addressed by the remaining categories',
      },
    ],
  },
  {
    label: 'Asset Maturity',
    group: 'maturity_version',
    items: [
      {
        value: 'Level 5: Very Mature',
        label: 'Level 5: Very Mature',
        helpText:
          'Several scientific studies support thesis/assertion through randomized controlled studies and equally strong evidence',
      },
      {
        value: 'Level 4: More Mature',
        label: 'Level 4: More Mature',
        helpText: 'Evidence supports thesis.',
      },
      {
        value: 'Level 3: Mature',
        label: 'Level 3: Mature',
        helpText:
          'Generally accepted opinion like subject matter expert or consensus opinion',
      },
      {
        value: 'Level 2: Less Mature',
        label: 'Level 2: Less Mature',
        helpText: 'Considered opinion/thesis, not tested',
      },
      {
        value: 'Level 1: Immature',
        label: 'Level 1: Immature',
        helpText: 'Reflects unsubstantiated, unproven, or postulated content',
      },
      { value: 'Unspecified', label: 'Unspecified', helpText: '' },
    ],
  },
  {
    label: 'Formalism(s)',
    group: 'formalism',
    items: [
      {
        value: 'narrative',
        label: 'Narrative',
        helpText:
          'Written description, also referred to as human readable and not machine readable (pending advanced natural language processing)',
      },
      {
        value: 'executable',
        label: 'Executable',
        helpText:
          'A representation that contains a set of instructions, which a computer interprets and runs, such as software or models',
      },
      {
        value: 'formalModel',
        label: 'Formal Model',
        helpText:
          'A representation with well-formed and precise model, syntax and semantics, such that it is amenable to systematic (usually automatable) processing and analysis subject to logical rules.',
      },
      {
        value: 'terminology',
        label: 'Terminology',
        helpText:
          'Controlled vocabularies in healthcare such as SNOMED-CT, RxNorm, ICD-10, LOINC, CPT etc.',
      },
      {
        value: 'dataTypes',
        label: 'Data Types',
        helpText:
          'Structured and unstructured data, standardized/non-standardized',
      },
      {
        value: 'apis',
        label: 'APIs',
        helpText:
          'APIs-subscription to data sources with permissions, structured entry and retrieval of data, APIs built to gather and present data from a data source to answer specific queries (i.e. all patients with a diagnosis of diabetes, heart disease, lung disease)',
      },
      { value: 'other', label: 'Other', helpText: '' },
    ],
  },
  {
    label: 'Knowledge Lifecycle Stage',
    group: 'knowledge_lifecycle_stage',
    items: [
      { value: 'identifyStudies', label: 'Identify Studies', helpText: '' },
      { value: 'rewiewEvidence', label: 'Rewiew Evidence', helpText: '' },
      { value: 'produceGuidance', label: 'Produce Guidance', helpText: '' },
      {
        value: 'makeGuidance',
        label: 'Make Guidance Computable',
        helpText: '',
      },
      { value: 'implementGuidance', label: 'Implement Guidance', helpText: '' },
      { value: 'analyzeResults', label: 'Analyze Results', helpText: '' },
      { value: 'applyResults', label: 'Apply Results', helpText: '' },
    ],
  },
  {
    label: 'Geography (soon)',
    group: 'geography',
    items: [],
  },
  // {
  //   label: 'Curation Value',
  //   items: [
  //     { value: 'referenced', label: 'Referenced' },
  //     { value: 'recommended', label: 'Recommended' },
  //     { value: 'unspecified', label: 'Unspecified' },
  //   ],
  // },
]

export const visibilityOptions = [
  { value: 'All', label: 'All' },
  { value: 'Assets', label: 'Assets' },
  { value: 'Activities', label: 'Activities' },
]

export const assetsFilters = [
  'publication_type',
  'maturity_version',
  'formalism',
  'source_type',
  'geography',
]

export const activitiesFilters = [
  'knowledge_lifecycle_stage',
  'source_type',
  'activity_type',
]
