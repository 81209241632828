export const curation = [
  { value: 'recommended', label: 'Recommended' },
  { value: 'referenced', label: 'Referenced' },
  { value: 'unspecified', label: 'Unspecified' },
]

export const formalism = [
  { value: 'narrative', label: 'Narrative' },
  { value: 'executable', label: 'Executable' },
  { value: 'formalModel', label: 'Formal Model' },
  { value: 'terminology', label: 'Terminology' },
  { value: 'dataTypes', label: 'Data Types' },
  { value: 'apis', label: 'APIs' },
  { value: 'other', label: 'Other' },
]

export const subject = [
  { value: 'features', label: 'Clinical Features and Diagnosis' },
  { value: 'diagnoses', label: 'Diagnoses' },
  { value: 'diagnostics', label: 'Diagnostics' },
  { value: 'comorb', label: 'Co-morbidities' },
  { value: 'caremgmt', label: 'Care Management' },
  { value: 'specpop', label: 'Special Populations' },
  {
    value: 'therapeuticsandprevention',
    label: 'Therapeutics and Prevention',
    className: 'mb-24',
  },
  { value: 'resmgmt', label: 'Resource Management' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'ppe', label: 'Personal Protection' },
  { value: 'control', label: 'Infection Control', className: 'mb-24' },
  { value: 'pubhealthcaserep', label: 'Public Health and Case Reporting' },
  { value: 'epid', label: 'Epidemiology' },
  { value: 'cliepid', label: 'Clinical Epidemiology' },
  { value: 'subjectreserch', label: 'Research', className: 'mb-24' },
  { value: 'ethics', label: 'Ethics' },
]

export const process = [
  { value: 'prevention', label: 'Prevention' },
  { value: 'screening', label: 'Screening' },
  { value: 'diagnosis', label: 'Diagnosis' },
  { value: 'treatment', label: 'Treatment' },
  { value: 'followup', label: 'Follow up' },
  { value: 'trial', label: 'Clinical Trial' },
]

export const activityTypes = [
  { value: 'research', label: 'Research' },
  { value: 'authoring', label: 'Authoring' },
  { value: 'implementation', label: 'Implementation' },
  { value: 'collaboration', label: 'Collaboration' },
]

export const setting = [
  { value: 'home', label: 'Home' },
  { value: 'telemedicine', label: 'Telemedicine' },
  { value: 'ambulatory', label: 'Ambulatory' },
  { value: 'acute', label: 'Acute Care' },
  { value: 'laboratoryenv', label: 'Laboratory Environments' },
  { value: 'longtermcare', label: 'Long Term Care' },
  { value: 'postacutecare', label: 'Post Acute Care' },
]

export const asset = [
  { value: 'datavis', label: 'Data Visualization' },
  { value: 'datacol', label: 'Data Collection' },
  { value: 'list', label: 'Directories and Lists' },
  { value: 'knowledge', label: 'Knowledge Resource' },
  { value: 'policy', label: 'Policy or Procedures', className: 'ml-22' },
  { value: 'assertion', label: 'Knowledge Assertion', className: 'ml-22' },
  { value: 'guideline', label: 'Guideline', className: 'ml-22' },
  { value: 'rules', label: 'CDS Rule(s)', className: 'ml-22' },
  { value: 'workflow', label: 'Workflow', className: 'ml-22' },
  { value: 'model', label: 'Model', className: 'ml-22' },
  { value: 'systemrev', label: 'Systematic Review', className: 'ml-22'},
  { value: 'recommend', label: 'Recommendation', className: 'ml-22'},
]

export const knowledgeLifecycleStage = [
  { value: 'identifyStudies', label: 'Identify Studies' },
  { value: 'rewiewEvidence', label: 'Review Evidence' },
  { value: 'produceGuidance', label: 'Produce Guidance' },
  { value: 'makeGuidance', label: 'Make Guidance Computable' },
  { value: 'implementGuidance', label: 'Implement Guidance' },
  { value: 'analyzeResults', label: 'Analyze Results' },
  { value: 'applyResults', label: 'Apply Results' },
]

export const languages = [
  {
    label: 'popular',
    options: [
      { value: 'en', label: 'English' },
      { value: 'zh', label: 'Chinese' },
      { value: 'hi', label: 'Hindi' },
      { value: 'es', label: 'Spanish' },
      { value: 'ar', label: 'Arabic' },
      { value: 'de', label: 'German' },
    ],
  },
  {
    label: 'others',
    options: [
      { value: 'All', label: 'All' },
      { value: 'ab', label: 'Abkhaz' },
      { value: 'aa', label: 'Afar' },
      { value: 'af', label: 'Afrikaans' },
      { value: 'ak', label: 'Akan' },
      { value: 'sq', label: 'Albanian' },
      { value: 'am', label: 'Amharic' },
      { value: 'ar', label: 'Arabic' },
      { value: 'an', label: 'Aragonese' },
      { value: 'hy', label: 'Armenian' },
      { value: 'as', label: 'Assamese' },
      { value: 'av', label: 'Avaric' },
      { value: 'ae', label: 'Avestan' },
      { value: 'ay', label: 'Aymara' },
      { value: 'az', label: 'Azerbaijani' },
      { value: 'bm', label: 'Bambara' },
      { value: 'ba', label: 'Bashkir' },
      { value: 'eu', label: 'Basque' },
      { value: 'be', label: 'Belarusian' },
      { value: 'bn', label: 'Bengali' },
      { value: 'bh', label: 'Bihari' },
      { value: 'bi', label: 'Bislama' },
      { value: 'bs', label: 'Bosnian' },
      { value: 'br', label: 'Breton' },
      { value: 'bg', label: 'Bulgarian' },
      { value: 'my', label: 'Burmese' },
      { value: 'ca', label: 'Catalan' },
      { value: 'ch', label: 'Chamorro' },
      { value: 'ce', label: 'Chechen' },
      { value: 'zh', label: 'Chinese' },
      { value: 'cv', label: 'Chuvash' },
      { value: 'kw', label: 'Cornish' },
      { value: 'co', label: 'Corsican' },
      { value: 'cr', label: 'Cree' },
      { value: 'hr', label: 'Croatian' },
      { value: 'cs', label: 'Czech' },
      { value: 'da', label: 'Danish' },
      { value: 'nl', label: 'Dutch' },
      { value: 'en', label: 'English' },
      { value: 'eo', label: 'Esperanto' },
      { value: 'et', label: 'Estonian' },
      { value: 'ee', label: 'Ewe' },
      { value: 'fo', label: 'Faroese' },
      { value: 'fj', label: 'Fijian' },
      { value: 'fi', label: 'Finnish' },
      { value: 'fr', label: 'French' },
      { value: 'gl', label: 'Galician' },
      { value: 'ka', label: 'Georgian' },
      { value: 'de', label: 'German' },
      { value: 'el', label: 'Greek' },
      { value: 'gn', label: 'Guaraní' },
      { value: 'gu', label: 'Gujarati' },
      { value: 'ht', label: 'Haitian' },
      { value: 'ha', label: 'Hausa' },
      { value: 'hz', label: 'Herero' },
      { value: 'hi', label: 'Hindi' },
      { value: 'ho', label: 'Hiri Motu' },
      { value: 'hu', label: 'Hungarian' },
      { value: 'ia', label: 'Interlingua' },
      { value: 'id', label: 'Indonesian' },
      { value: 'ga', label: 'Irish' },
      { value: 'ig', label: 'Igbo' },
      { value: 'ik', label: 'Inupiaq' },
      { value: 'io', label: 'Ido' },
      { value: 'is', label: 'Icelandic' },
      { value: 'it', label: 'Italian' },
      { value: 'iu', label: 'Inuktitut' },
      { value: 'ja', label: 'Japanese' },
      { value: 'jv', label: 'Javanese' },
      { value: 'kl', label: 'Kalaallisut' },
      { value: 'kn', label: 'Kannada' },
      { value: 'kr', label: 'Kanuri' },
      { value: 'ks', label: 'Kashmiri' },
      { value: 'kk', label: 'Kazakh' },
      { value: 'km', label: 'Khmer' },
      { value: 'rw', label: 'Kinyarwanda' },
      { value: 'ky', label: 'Kirghiz' },
      { value: 'kv', label: 'Komi' },
      { value: 'kg', label: 'Kongo' },
      { value: 'ko', label: 'Korean' },
      { value: 'la', label: 'Latin' },
      { value: 'ln', label: 'Lingala' },
      { value: 'lo', label: 'Lao' },
      { value: 'lt', label: 'Lithuanian' },
      { value: 'lv', label: 'Latvian' },
      { value: 'gv', label: 'Manx' },
      { value: 'mk', label: 'Macedonian' },
      { value: 'mg', label: 'Malagasy' },
      { value: 'ml', label: 'Malayalam' },
      { value: 'mt', label: 'Maltese' },
      { value: 'mi', label: 'Māori' },
      { value: 'mh', label: 'Marshallese' },
      { value: 'mn', label: 'Mongolian' },
      { value: 'na', label: 'Nauru' },
      { value: 'ne', label: 'Nepali' },
      { value: 'ng', label: 'Ndonga' },
      { value: 'nn', label: 'Norwegian' },
      { value: 'ii', label: 'Nuosu' },
      { value: 'oc', label: 'Occitan' },
      { value: 'om', label: 'Oromo' },
      { value: 'or', label: 'Oriya' },
      { value: 'pi', label: 'Pāli' },
      { value: 'fa', label: 'Persian' },
      { value: 'pl', label: 'Polish' },
      { value: 'pt', label: 'Portuguese' },
      { value: 'qu', label: 'Quechua' },
      { value: 'rm', label: 'Romansh' },
      { value: 'ro', label: 'Romanian' },
      { value: 'ru', label: 'Russian' },
      { value: 'sa', label: 'Sanskrit' },
      { value: 'sc', label: 'Sardinian' },
      { value: 'sd', label: 'Sindhi' },
      { value: 'sm', label: 'Samoan' },
      { value: 'sg', label: 'Sango' },
      { value: 'sr', label: 'Serbian' },
      { value: 'sn', label: 'Shona' },
      { value: 'sk', label: 'Slovak' },
      { value: 'sl', label: 'Slovene' },
      { value: 'so', label: 'Somali' },
      { value: 'es', label: 'Spanish' },
      { value: 'su', label: 'Sundanese' },
      { value: 'sw', label: 'Swahili' },
      { value: 'ss', label: 'Swati' },
      { value: 'sv', label: 'Swedish' },
      { value: 'ta', label: 'Tamil' },
      { value: 'te', label: 'Telugu' },
      { value: 'tg', label: 'Tajik' },
      { value: 'th', label: 'Thai' },
      { value: 'ti', label: 'Tigrinya' },
      { value: 'bo', label: 'Tibetan' },
      { value: 'tk', label: 'Turkmen' },
      { value: 'tl', label: 'Tagalog' },
      { value: 'tn', label: 'Tswana' },
      { value: 'to', label: 'Tonga' },
      { value: 'tr', label: 'Turkish' },
      { value: 'ts', label: 'Tsonga' },
      { value: 'tt', label: 'Tatar' },
      { value: 'tw', label: 'Twi' },
      { value: 'ty', label: 'Tahitian' },
      { value: 'ug', label: 'Uighur' },
      { value: 'uk', label: 'Ukrainian' },
      { value: 'ur', label: 'Urdu' },
      { value: 'uz', label: 'Uzbek' },
      { value: 've', label: 'Venda' },
      { value: 'vo', label: 'Volapük' },
      { value: 'wa', label: 'Walloon' },
      { value: 'cy', label: 'Welsh' },
      { value: 'wo', label: 'Wolof' },
      { value: 'xh', label: 'Xhosa' },
      { value: 'yi', label: 'Yiddish' },
      { value: 'yo', label: 'Yoruba' },
      { value: 'za', label: 'Zhuang' },
    ],
  },
]

export const maturity = [
  { value: 'level_5', label: 'Level 5: Very Mature' },
  { value: 'level_4', label: 'Level 4: More Mature' },
  { value: 'level_3', label: 'Level 3: Mature' },
  { value: 'level_2', label: 'Level 2: Less Mature' },
  { value: 'level_1', label: 'Level 1: Immature' },
  { value: 'unspecified', label: 'Unspecified' },
]

export const status = [
  {
    value: 'Draft',
    label: 'Draft',
    color: '#33333399',
    backgroundColor: '#5151511a',
    fontWeight: '500',
  },
  {
    value: 'Unclassified',
    label: 'Submitted',
    color: '#ff8c40',
    backgroundColor: '#ff8c404d',
    fontWeight: '500',
  },
  {
    value: 'Preclassified',
    label: 'Preclassified',
    color: '#ce5685',
    backgroundColor: '#ce56854d',
    fontWeight: '500',
  },
  {
    value: 'Classified',
    label: 'Classified',
    color: '#786cba',
    backgroundColor: '#786cba4d',
    fontWeight: '500',
  },
  {
    value: 'Verified',
    label: 'Published',
    color: '#6ca755',
    backgroundColor: '#6ca7554d',
    fontWeight: '500',
  },
]

export const statusDescriptions = {
  Draft: 'Visible to owner of the asset only',
  Unclassified: 'Waiting for classification',
  Preclassified: 'Waiting for final classification',
  Classified: 'Waiting for verification',
  Verified: 'Visible to all users and visitors',
}

export const activitiesStatusDescriptions = {
  Draft: 'Visible to owner of the activity only',
  Unclassified: 'Waiting for classification',
  Preclassified: 'Waiting for final classification',
  Classified: 'Waiting for verification',
  Verified: 'Visible to all users and visitors',
}

export const feedbackStatus = [
  {
    value: 'Resolved',
    label: 'Resolved',
    color: '#6ca755',
    fontWeight: '500',
  },
  {
    value: 'Important',
    label: 'Important',
    color: '#ce5685',
    fontWeight: '500',
  },
  {
    value: 'New',
    label: 'New',
    color: '#ff8c40',
    fontWeight: '500',
  },
  { value: 'Ignore', label: 'Ignore', color: '#33333399', fontWeight: '500' },
]

export const geopraphy = [
  { value: 'Global', label: 'Global' },
  { value: 'Non-Global', label: 'Non-Global' },
  { value: 'Unknown', label: 'Unknown' },
]

export const publicationTypes = [
  { value: 'review', label: 'Systematic Review' },
  { value: 'consensus', label: 'Consensus Opinion' },
  { value: 'paper', label: 'Paper/Article' },
  { value: 'editorial', label: 'Editorial' },
  { value: 'letter', label: 'Letter to the Editor' },
  { value: 'news', label: 'News' },
  { value: 'web', label: 'Web' },
  { value: 'other', label: 'Other' },
]

export const source = [
  { value: 'government', label: 'Government-Sourced' },
  { value: 'ngo', label: 'NGO-Sourced' },
  { value: 'commercially', label: 'Commercially Sourced' },
  { value: 'college', label: 'College/Professional Organization' },
  { value: 'healthcare', label: 'Healthcare Delivery System' },
  { value: 'other', label: 'Other' },
]

export const roles = [
  { value: 'directCare', label: 'Direct Care' },
  { value: 'publicHealth', label: 'Public Health' },
  { value: 'administration', label: 'Administration' },
  { value: 'patient', label: 'Patient' },
  { value: 'research', label: 'Research' },
]
