export const graphSliderDefault = {
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
}

export const graphSliderActive = {
  0: 'SIMPLE',
  1: '',
  2: '',
  3: '',
  4: 'COMPLEX',
}
